#transactionHistoryFilterContainer {
  color: #d5d5d5;
}
#transactionHistoryListContainer {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}
#transactionHistoryListContainer::-webkit-scrollbar {
  width: 100%;
  height: 5px;
}
#transactionHistoryListContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fcd301;
  outline: 1 px solid #708090;
}
#transactionHistoryListContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
}
.custom-table.with-margin {
  border-collapse: separate;
  border-spacing: 2px;
  min-width: 900px;
}

.custom-table {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
table {
  background-color: transparent;
}
.custom-table.zebra-row tbody tr:nth-child(odd) {
  background-color: #1f1f1f;
}
.custom-table.zebra-row tbody tr:nth-child(odd) td {
  color: #d5d5d5;
}
#transactionHistoryListContainer .custom-table tbody tr td:nth-child(2) {
  width: 150px;
}
#transactionHistoryListContainer .custom-table tbody tr td:nth-child(3) {
  width: 400px;
}
.table-total-field {
  border-radius: 3px;
  margin: 1px;
  background-color: #232323;
  font-size: 14px;
  color: #d5d5d5;
}
.list-justify li,
.table-total-field {
  display: flex;
  justify-content: space-between;
}
.table-total-field li {
  padding: 10px;
}
.table-total-field li span:first-child {
  font-weight: 700;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li > a,
.pagination > li > button,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > button,
.pagination > .disabled > button:focus,
.pagination > .disabled > button:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > li:first-child > a,
.pagination > li:first-child > button,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > button,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a,
.pagination > li > button,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > button,
.pagination > .active > button:focus,
.pagination > .active > button:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}
.no-data-information .nvs-no-data-wrapper {
  padding: 15px 40px;
  text-align: center;
  font-size: 20px;
  color: #d5d5d5;
  background-color: #313131;
  border-color: #313131;
  border-radius: 3px;
}
.icon-problem:before {
  content: "\f071";
}
.no-data-information .nvs-no-data-wrapper .nvs-no-data-info {
  font-weight: 600;
  font-size: 0.75em;
  color: #d5d5d5;
  margin: 0;
}
