.casino-td {
  border-bottom: 1px solid #ddd;
  border-top: none;
  padding: 2px 0 !important;
  color: #181818;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.liked-games .panel table tbody > tr:first-child td {
  border-top: none;
}
.casino-td label {
  margin: 0;
  display: block;
}
.casino-td label .providerItem {
  display: flex;
  justify-content: space-between;
  padding: 0 15% 0 5%;
}
.casino-td label .providerItem span {
  text-transform: capitalize;
  text-align: left;
}
.casino-td label .providerItem span {
  text-transform: capitalize;
  text-align: left;
}
.panel-body:after,
.panel-body:before {
  content: " ";
  display: table;
}
.panel-body {
  padding: 15px;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-body:after {
  clear: both;
}
.active-providermobile > .providerItem:after {
  font-family: FontAwesome, sans-serif;
  content: "\f00c ";
  position: absolute;
  right: 10px;
  top: 0;
}
.active-providermobile {
  color: #8d0400;
  position: relative;
}
