#accountListContainer,
#affiliateTransferContainer,
#agentReportContainer {
  position: relative;
  overflow-x: auto;
}
#accountListContainer::-webkit-scrollbar {
  width: 100%;
  height: 5px;
}
#accountListContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fcd301;
  outline: 1 px solid #708090;
}
#accountListContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
}
@media (max-width: 767px) {
  #accountListContainer {
    padding-top: 3px;
  }
}

.nvs-AccountListTable .nvs-AccountListTable-header-labels {
  background-color: #1f1f1f;
  color: #d5d5d5;
  display: block;
  float: left;
  border: 1px solid #181818;
  width: 11%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding-right: 3px;
  max-width: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nvs-AccountListTable .nvs-AccountListTable-header-labels.actions,
.nvs-AccountListTable .nvs-AccountListTable-header-labels.sub-level {
  width: 10%;
}
.nvs-AccountListTable .nvs-AccountListTable-header-labels.symbol-labels {
  width: 10%;
}
#accountListContainer
  .nvs-AccountListTable
  .nvs-AccountListTable-header-labels {
  background-color: #fcd301;
}
#accountListContainer .nvs-AccountListTable .nvs-AccountListTable-header-labels,
#agentReportListTotalContainer .custom-table thead tr th,
#agentReportUserTemplateContainer .custom-table thead tr th,
#commissionReportDetailContainer .detailsList .commissionTable table thead tr,
#commissionReportUserDetailContainer
  .userDetailsListPromoterView
  .commissionTable
  .tableContainer
  .tableheader
  .tableHeaderRow,
#nvsBalanceReportListContainer
  .nvs-BalanceReportTable-wrapper
  .nvs-BalanceReportTable
  .nvs-BalanceReportTable-header-labels,
#nvsCommissionReportListContainer
  .commissionTable
  .commissionTableContainer
  .commissionTableHeader
  .commissionTableHeaderRow,
#nvsRevenueReportListContainer
  .nvs-RevenueReportTable-wrapper
  .nvs-RevenueReportTable
  .nvs-RevenueReportTable-header-labels {
  color: #1f1f1f;
  font-weight: 700;
  font-size: 12px;
}
.nvs-AccountListTable {
  width: 100%;
  font-size: 0.9125em;
  min-width: 1000px;
}

.nvs-AccountListTable .nvs-AccountListTable-header {
  display: block;
  font-weight: 600;
  width: 100%;
}
#bet-register:after,
#bet-register:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body
  .nvs-CashierReportTable-body-row:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body
  .nvs-CashierReportTable-body-row:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-header:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-header:before,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper
  .nvs-fast-betting-bet-register-markets-wrapper
  .nvs-fast-betting-bet-register-odds-wrapper:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper
  .nvs-fast-betting-bet-register-markets-wrapper
  .nvs-fast-betting-bet-register-odds-wrapper:before,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper:before,
#fast-betting-bet-register-container:after,
#fast-betting-bet-register-container:before,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper
  .nvs-fast-betting-event-markets-wrapper
  .nvs-fast-betting-event-odds-wrapper:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper
  .nvs-fast-betting-event-markets-wrapper
  .nvs-fast-betting-event-odds-wrapper:before,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper:before,
#inboxContainer .nvs-inbox-filter-wrapper:after,
#inboxContainer .nvs-inbox-filter-wrapper:before,
#inboxContainer .nvs-inbox-list-wrapper:after,
#inboxContainer .nvs-inbox-list-wrapper:before,
#inboxContainer:after,
#inboxContainer:before,
#nvs3DPopup .modal-body .nvs-bet-code-popup-body:after,
#nvs3DPopup .modal-body .nvs-bet-code-popup-body:before,
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body:after,
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body:before,
#nvs-AccountList-editPasswordContainer .nvs-AccountList-editPassword-body:after,
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body:before,
#nvs-AccountList-transferContainer
  .nvs-AccountList-transfer-body
  .nvs-AccountList-transfer-body-actions:after,
#nvs-AccountList-transferContainer
  .nvs-AccountList-transfer-body
  .nvs-AccountList-transfer-body-actions:before,
#nvs-AccountList-transferContainer .nvs-AccountList-transfer-body:after,
#nvs-AccountList-transferContainer .nvs-AccountList-transfer-body:before,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper
  .nvs-fast-betting-sidebar-event-markets-wrapper
  .nvs-fast-betting-sidebar-event-odds-wrapper:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper
  .nvs-fast-betting-sidebar-event-markets-wrapper
  .nvs-fast-betting-sidebar-event-odds-wrapper:before,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper:before,
#nvs-fast-betting-sidebar-container:after,
#nvs-fast-betting-sidebar-container:before,
#nvs-header .nvs-header-row .nvs-header-container:after,
#nvs-header .nvs-header-row .nvs-header-container:before,
#nvs-header .nvs-header-row:after,
#nvs-header .nvs-header-row:before,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-business-form-wrapper:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-business-form-wrapper:before,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-form-container:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-form-container:before,
#nvsAffiliateContainer:after,
#nvsAffiliateContainer:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body
  .nvs-AffiliateTransferTable-body-row:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body
  .nvs-AffiliateTransferTable-body-row:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-header:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-header:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body
  .nvs-AgentReportDateDetailTable-body-row:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body
  .nvs-AgentReportDateDetailTable-body-row:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-header:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-header:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body
  .nvs-AgentReportDateTable-body-row:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body
  .nvs-AgentReportDateTable-body-row:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-header:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-header:before,
#nvsAgentReportListContainer .nvsAgentReportDateTemplate:after,
#nvsAgentReportListContainer .nvsAgentReportDateTemplate:before,
#nvsAgentReportListContainer .nvsAgentReportUserTemplate:after,
#nvsAgentReportListContainer .nvsAgentReportUserTemplate:before,
#nvsBetCodePopup .modal-body .nvs-bet-code-popup-body:after,
#nvsBetCodePopup .modal-body .nvs-bet-code-popup-body:before,
#nvsDailyMatchesContainer .nvs-oddsFilter-list-wrapper:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-list-wrapper:before,
#nvsDailyMatchesContainer .nvs-oddsFilter-search-wrapper:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-search-wrapper:before,
#nvsDailyMatchesSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsDailyMatchesSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsInboxListContainer .nvs-inbox-list-wrapper .nvs-inbox-list-item:after,
#nvsInboxListContainer .nvs-inbox-list-wrapper .nvs-inbox-list-item:before,
#nvsLive .nvs-live-content .nvs-live-header:after,
#nvsLive .nvs-live-content .nvs-live-header:before,
#nvsMessageContainer .nvs-message-wrapper:after,
#nvsMessageContainer .nvs-message-wrapper:before,
#nvsMessageContainer:after,
#nvsMessageContainer:before,
#nvsOddsFilterContainer .nvs-oddsFilter-list-wrapper:after,
#nvsOddsFilterContainer .nvs-oddsFilter-list-wrapper:before,
#nvsOddsFilterContainer .nvs-oddsFilter-search-wrapper:after,
#nvsOddsFilterContainer .nvs-oddsFilter-search-wrapper:before,
#nvsOddsFilterSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsOddsFilterSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsOddsPage .odds-container .content-item .market-groups:after,
#nvsOddsPage .odds-container .content-item .market-groups:before,
#nvsOddsPage
  .odds-container
  .content-item
  .tournament
  .tournament-buttons:after,
#nvsOddsPage
  .odds-container
  .content-item
  .tournament
  .tournament-buttons:before,
#nvsOddsPage
  .odds-container.sort-by-tournament
  .market-groups-container
  .market-groups:after,
#nvsOddsPage
  .odds-container.sort-by-tournament
  .market-groups-container
  .market-groups:before,
#nvsOddsPage .odds-container.sort-by-tournament .tournament-buttons:after,
#nvsOddsPage .odds-container.sort-by-tournament .tournament-buttons:before,
#nvsPrintResultSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsPrintResultSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsSportContainer:after,
#nvsSportContainer:before,
#nvsSportContentContainer:after,
#nvsSportContentContainer:before,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-body:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-body:before,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-header:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-header:before,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-body:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-body:before,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-header:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-header:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body-row:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body-row:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-header:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-header:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket:before,
#registerFormContainer .form-wrapper .segment:after,
#registerFormContainer .form-wrapper .segment:before,
#registerFormContainer .form-wrapper:after,
#registerFormContainer .form-wrapper:before,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticChart:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticChart:before,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticTable:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticTable:before,
#withdrawContainer #methodContainer:after,
#withdrawContainer #methodContainer:before,
#withdrawContainer:after,
#withdrawContainer:before,
.clearfix:after,
.clearfix:before,
.header-menu-loggedOut:after,
.header-menu-loggedOut:before,
.header-menu-register:after,
.header-menu-register:before,
.navbar-content-menu
  .navbar-content-menu-wrapper
  .navbar-content-menu-container:after,
.navbar-content-menu
  .navbar-content-menu-wrapper
  .navbar-content-menu-container:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:after,
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:before,
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row:before,
.nvs-AccountListTable .nvs-AccountListTable-body:after,
.nvs-AccountListTable .nvs-AccountListTable-body:before,
.nvs-AccountListTable .nvs-AccountListTable-header:after,
.nvs-AccountListTable .nvs-AccountListTable-header:before,
.nvs-BalanceReportTable .nvs-BalanceReportTable-header:after,
.nvs-BalanceReportTable .nvs-BalanceReportTable-header:before,
.nvs-betHistoryDetail--button-wrapper:after,
.nvs-betHistoryDetail--button-wrapper:before,
.nvs-check-coupon-container .nvsCheckCoupon-entry:after,
.nvs-check-coupon-container .nvsCheckCoupon-entry:before,
.nvs-header-navbar-date-time:after,
.nvs-header-navbar-date-time:before,
.nvs-home:after,
.nvs-home:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-event:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-event:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-odds-wrapper:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-odds-wrapper:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper:before,
.nvs-latest-winners-container .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container .nvsLatestWinnerItem-gain:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-gain:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-info:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-info:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-event:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-event:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-odds-wrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-odds-wrapper:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper:before,
.nvs-mixed-view-container
  .nvs-mixed-view-item
  .nvs-mixed-view-sport-container
  .nvs-mixed-view-sport-event-container
  .nvs-mixed-view-sport-event-item-container
  .nvs-mixed-view-sport-event-item-content
  .nvs-mixed-view-sport-event-content.sport-type-3
  .nvs-mixed-view-event-score-container
  .nvs-mixed-view-event-score:after,
.nvs-mixed-view-container
  .nvs-mixed-view-item
  .nvs-mixed-view-sport-container
  .nvs-mixed-view-sport-event-container
  .nvs-mixed-view-sport-event-item-container
  .nvs-mixed-view-sport-event-item-content
  .nvs-mixed-view-sport-event-content.sport-type-3
  .nvs-mixed-view-event-score-container
  .nvs-mixed-view-event-score:before,
.nvs-products-menu-container:after,
.nvs-products-menu-container:before,
.nvs-RevenueReportTable
  .nvs-RevenueReportTable-body
  .nvs-RevenueReportTable-body-row:after,
.nvs-RevenueReportTable
  .nvs-RevenueReportTable-body
  .nvs-RevenueReportTable-body-row:before,
.nvs-RevenueReportTable .nvs-RevenueReportTable-body:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-body:before,
.nvs-RevenueReportTable .nvs-RevenueReportTable-header:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-header:before,
.nvs-RevenueReportTable:after,
.nvs-RevenueReportTable:before,
.nvs-simple-view-container
  .nvs-simple-view-item
  .nvs-simple-view-sport-container
  .nvs-simple-view-sport-event-container
  .nvs-simple-view-sport-event-item-container
  .nvs-simple-view-sport-event-item-content
  .nvs-simple-view-sport-event-content.sport-type-3
  .nvs-simple-view-event-score-container
  .nvs-simple-view-event-score:after,
.nvs-simple-view-container
  .nvs-simple-view-item
  .nvs-simple-view-sport-container
  .nvs-simple-view-sport-event-container
  .nvs-simple-view-sport-event-item-container
  .nvs-simple-view-sport-event-item-content
  .nvs-simple-view-sport-event-content.sport-type-3
  .nvs-simple-view-event-score-container
  .nvs-simple-view-event-score:before,
.nvs-ticket-content-event-group
  .nvs-goal-scorer-ticket-content-event-group-item
  .nvs-goal-scorer-ticket-content-event-container
  .nvs-goal-scorer-ticket-content-event-info:after,
.nvs-ticket-content-event-group
  .nvs-goal-scorer-ticket-content-event-group-item
  .nvs-goal-scorer-ticket-content-event-container
  .nvs-goal-scorer-ticket-content-event-info:before,
.nvs-ticket-content-event-group
  .nvs-ticket-content-event-group-item
  .nvs-ticket-content-event-info:after,
.nvs-ticket-content-event-group
  .nvs-ticket-content-event-group-item
  .nvs-ticket-content-event-info:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header
  .nvs-odds-page--odds--content--odds--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header
  .nvs-odds-page--odds--content--odds--header--inner:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds
  .nvs-odds-page--odds--content--event--content--odds--item
  .nvs-odds-page--odds--content--event--odds--row:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds
  .nvs-odds-page--odds--content--event--content--odds--item
  .nvs-odds-page--odds--content--event--odds--row:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner
  .nvs-odds-page--odds--content--odds--sub--header--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner
  .nvs-odds-page--odds--content--odds--sub--header--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header:before,
.odds-table.normal
  .content--inner
  .event
  .event-content--odds
  .event-content--odds--item
  .event-odds--row:after,
.odds-table.normal
  .content--inner
  .event
  .event-content--odds
  .event-content--odds--item
  .event-odds--row:before,
.odds-table.normal .content--inner .event:after,
.odds-table.normal .content--inner .event:before,
.odds-table.normal .content .content--header .odd-header-inner:after,
.odds-table.normal .content .content--header .odd-header-inner:before,
.odds-table.normal .content .content--header:after,
.odds-table.normal .content .content--header:before,
.odds-table.normal .content .sub-header .inner .item:after,
.odds-table.normal .content .sub-header .inner .item:before,
.odds-table.normal .content .sub-header .inner:after,
.odds-table.normal .content .sub-header .inner:before,
.odds-table.normal .content .sub-header:after,
.odds-table.normal .content .sub-header:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-content:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-content:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header
  .nvs-ticket-combinations-table-pot-winnings-header
  .nvs-ticket-combinations-table-min-max-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header
  .nvs-ticket-combinations-table-pot-winnings-header
  .nvs-ticket-combinations-table-min-max-header:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header:before,
.ticket-loaded .nvs-ticket-header-button-group:after,
.ticket-loaded .nvs-ticket-header-button-group:before,
.ticket-loaded
  .nvs-ticket-place-area
  .nvs-ticket-place-area-reset-save-button-group:after,
.ticket-loaded
  .nvs-ticket-place-area
  .nvs-ticket-place-area-reset-save-button-group:before,
.ticket-loaded .nvs-ticket-place-area .nvs-ticket-place-stake-group:after,
.ticket-loaded .nvs-ticket-place-area .nvs-ticket-place-stake-group:before,
.transfer-container .transfer-inputs-container .transfer-input-sections:after,
.transfer-container .transfer-inputs-container .transfer-input-sections:before {
  content: " ";
  display: table;
}
#bet-register:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body
  .nvs-CashierReportTable-body-row:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-header:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper
  .nvs-fast-betting-bet-register-markets-wrapper
  .nvs-fast-betting-bet-register-odds-wrapper:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper:after,
#fast-betting-bet-register-container:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper
  .nvs-fast-betting-event-markets-wrapper
  .nvs-fast-betting-event-odds-wrapper:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper:after,
#inboxContainer .nvs-inbox-filter-wrapper:after,
#inboxContainer .nvs-inbox-list-wrapper:after,
#inboxContainer:after,
#nvs3DPopup .modal-body .nvs-bet-code-popup-body:after,
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body:after,
#nvs-AccountList-editPasswordContainer .nvs-AccountList-editPassword-body:after,
#nvs-AccountList-transferContainer
  .nvs-AccountList-transfer-body
  .nvs-AccountList-transfer-body-actions:after,
#nvs-AccountList-transferContainer .nvs-AccountList-transfer-body:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper
  .nvs-fast-betting-sidebar-event-markets-wrapper
  .nvs-fast-betting-sidebar-event-odds-wrapper:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper:after,
#nvs-fast-betting-sidebar-container:after,
#nvs-header .nvs-header-row .nvs-header-container:after,
#nvs-header .nvs-header-row:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-business-form-wrapper:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-form-container:after,
#nvsAffiliateContainer:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body
  .nvs-AffiliateTransferTable-body-row:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-header:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body
  .nvs-AgentReportDateDetailTable-body-row:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-header:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body
  .nvs-AgentReportDateTable-body-row:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-header:after,
#nvsAgentReportListContainer .nvsAgentReportDateTemplate:after,
#nvsAgentReportListContainer .nvsAgentReportUserTemplate:after,
#nvsBetCodePopup .modal-body .nvs-bet-code-popup-body:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-list-wrapper:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-search-wrapper:after,
#nvsDailyMatchesSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsInboxListContainer .nvs-inbox-list-wrapper .nvs-inbox-list-item:after,
#nvsLive .nvs-live-content .nvs-live-header:after,
#nvsMessageContainer .nvs-message-wrapper:after,
#nvsMessageContainer:after,
#nvsOddsFilterContainer .nvs-oddsFilter-list-wrapper:after,
#nvsOddsFilterContainer .nvs-oddsFilter-search-wrapper:after,
#nvsOddsFilterSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsOddsPage .odds-container .content-item .market-groups:after,
#nvsOddsPage
  .odds-container
  .content-item
  .tournament
  .tournament-buttons:after,
#nvsOddsPage
  .odds-container.sort-by-tournament
  .market-groups-container
  .market-groups:after,
#nvsOddsPage .odds-container.sort-by-tournament .tournament-buttons:after,
#nvsPrintResultSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsSportContainer:after,
#nvsSportContentContainer:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-body:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-header:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-body:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-header:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body-row:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-header:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket:after,
#registerFormContainer .form-wrapper .segment:after,
#registerFormContainer .form-wrapper:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticChart:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticTable:after,
#withdrawContainer #methodContainer:after,
#withdrawContainer:after,
.clearfix:after,
.header-menu-loggedOut:after,
.header-menu-register:after,
.navbar-content-menu
  .navbar-content-menu-wrapper
  .navbar-content-menu-container:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row:after,
.nvs-AccountListTable .nvs-AccountListTable-body:after,
.nvs-AccountListTable .nvs-AccountListTable-header:after,
.nvs-BalanceReportTable .nvs-BalanceReportTable-header:after,
.nvs-betHistoryDetail--button-wrapper:after,
.nvs-check-coupon-container .nvsCheckCoupon-entry:after,
.nvs-header-navbar-date-time:after,
.nvs-home:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-event:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-odds-wrapper:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper:after,
.nvs-latest-winners-container .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-info:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-event:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-odds-wrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper:after,
.nvs-mixed-view-container
  .nvs-mixed-view-item
  .nvs-mixed-view-sport-container
  .nvs-mixed-view-sport-event-container
  .nvs-mixed-view-sport-event-item-container
  .nvs-mixed-view-sport-event-item-content
  .nvs-mixed-view-sport-event-content.sport-type-3
  .nvs-mixed-view-event-score-container
  .nvs-mixed-view-event-score:after,
.nvs-products-menu-container:after,
.nvs-RevenueReportTable
  .nvs-RevenueReportTable-body
  .nvs-RevenueReportTable-body-row:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-body:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-header:after,
.nvs-RevenueReportTable:after,
.nvs-simple-view-container
  .nvs-simple-view-item
  .nvs-simple-view-sport-container
  .nvs-simple-view-sport-event-container
  .nvs-simple-view-sport-event-item-container
  .nvs-simple-view-sport-event-item-content
  .nvs-simple-view-sport-event-content.sport-type-3
  .nvs-simple-view-event-score-container
  .nvs-simple-view-event-score:after,
.nvs-ticket-content-event-group
  .nvs-goal-scorer-ticket-content-event-group-item
  .nvs-goal-scorer-ticket-content-event-container
  .nvs-goal-scorer-ticket-content-event-info:after,
.nvs-ticket-content-event-group
  .nvs-ticket-content-event-group-item
  .nvs-ticket-content-event-info:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header
  .nvs-odds-page--odds--content--odds--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds
  .nvs-odds-page--odds--content--event--content--odds--item
  .nvs-odds-page--odds--content--event--odds--row:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner
  .nvs-odds-page--odds--content--odds--sub--header--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header:after,
.odds-table.normal
  .content--inner
  .event
  .event-content--odds
  .event-content--odds--item
  .event-odds--row:after,
.odds-table.normal .content--inner .event:after,
.odds-table.normal .content .content--header .odd-header-inner:after,
.odds-table.normal .content .content--header:after,
.odds-table.normal .content .sub-header .inner .item:after,
.odds-table.normal .content .sub-header .inner:after,
.odds-table.normal .content .sub-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-content:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header
  .nvs-ticket-combinations-table-pot-winnings-header
  .nvs-ticket-combinations-table-min-max-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header:after,
.ticket-loaded .nvs-ticket-header-button-group:after,
.ticket-loaded
  .nvs-ticket-place-area
  .nvs-ticket-place-area-reset-save-button-group:after,
.ticket-loaded .nvs-ticket-place-area .nvs-ticket-place-stake-group:after,
.transfer-container .transfer-inputs-container .transfer-input-sections:after {
  clear: both;
}
#bet-register:after,
#bet-register:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body
  .nvs-CashierReportTable-body-row:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body
  .nvs-CashierReportTable-body-row:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-body:before,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-header:after,
#cashierReportListContainer
  .nvs-CashierReportTable
  .nvs-CashierReportTable-header:before,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper
  .nvs-fast-betting-bet-register-markets-wrapper
  .nvs-fast-betting-bet-register-odds-wrapper:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper
  .nvs-fast-betting-bet-register-markets-wrapper
  .nvs-fast-betting-bet-register-odds-wrapper:before,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper:after,
#fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-container
  .nvs-fast-betting-bet-register-wrapper:before,
#fast-betting-bet-register-container:after,
#fast-betting-bet-register-container:before,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper
  .nvs-fast-betting-event-markets-wrapper
  .nvs-fast-betting-event-odds-wrapper:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper
  .nvs-fast-betting-event-markets-wrapper
  .nvs-fast-betting-event-odds-wrapper:before,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper:after,
#fast-betting-sport-container
  .nvs-fast-betting-event-container
  .nvs-fast-betting-event-wrapper:before,
#inboxContainer .nvs-inbox-filter-wrapper:after,
#inboxContainer .nvs-inbox-filter-wrapper:before,
#inboxContainer .nvs-inbox-list-wrapper:after,
#inboxContainer .nvs-inbox-list-wrapper:before,
#inboxContainer:after,
#inboxContainer:before,
#nvs3DPopup .modal-body .nvs-bet-code-popup-body:after,
#nvs3DPopup .modal-body .nvs-bet-code-popup-body:before,
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body:after,
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body:before,
#nvs-AccountList-editPasswordContainer .nvs-AccountList-editPassword-body:after,
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body:before,
#nvs-AccountList-transferContainer
  .nvs-AccountList-transfer-body
  .nvs-AccountList-transfer-body-actions:after,
#nvs-AccountList-transferContainer
  .nvs-AccountList-transfer-body
  .nvs-AccountList-transfer-body-actions:before,
#nvs-AccountList-transferContainer .nvs-AccountList-transfer-body:after,
#nvs-AccountList-transferContainer .nvs-AccountList-transfer-body:before,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper
  .nvs-fast-betting-sidebar-event-markets-wrapper
  .nvs-fast-betting-sidebar-event-odds-wrapper:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper
  .nvs-fast-betting-sidebar-event-markets-wrapper
  .nvs-fast-betting-sidebar-event-odds-wrapper:before,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper:after,
#nvs-fast-betting-sidebar-container
  .nvs-fast-betting-sidebar-event-container
  .nvs-fast-betting-sidebar-event-wrapper:before,
#nvs-fast-betting-sidebar-container:after,
#nvs-fast-betting-sidebar-container:before,
#nvs-header .nvs-header-row .nvs-header-container:after,
#nvs-header .nvs-header-row .nvs-header-container:before,
#nvs-header .nvs-header-row:after,
#nvs-header .nvs-header-row:before,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-business-form-wrapper:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-business-form-wrapper:before,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-form-container:after,
#nvsAffiliateContainer
  .nvs-affiliate-wrapper
  .nvs-affiliate-form-wrapper
  .nvs-affiliate-form-container:before,
#nvsAffiliateContainer:after,
#nvsAffiliateContainer:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body
  .nvs-AffiliateTransferTable-body-row:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body
  .nvs-AffiliateTransferTable-body-row:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-body:before,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-header:after,
#nvsAffiliateTransferListContainer
  .nvs-AffiliateTransferTable
  .nvs-AffiliateTransferTable-header:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body
  .nvs-AgentReportDateDetailTable-body-row:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body
  .nvs-AgentReportDateDetailTable-body-row:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-body:before,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-header:after,
#nvsAgentReportDateDetailsContainer
  .nvs-AgentReportDateDetailTable
  .nvs-AgentReportDateDetailTable-header:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body
  .nvs-AgentReportDateTable-body-row:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body
  .nvs-AgentReportDateTable-body-row:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-body:before,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-header:after,
#nvsAgentReportDateTemplateContainer
  .nvs-AgentReportDateTable
  .nvs-AgentReportDateTable-header:before,
#nvsAgentReportListContainer .nvsAgentReportDateTemplate:after,
#nvsAgentReportListContainer .nvsAgentReportDateTemplate:before,
#nvsAgentReportListContainer .nvsAgentReportUserTemplate:after,
#nvsAgentReportListContainer .nvsAgentReportUserTemplate:before,
#nvsBetCodePopup .modal-body .nvs-bet-code-popup-body:after,
#nvsBetCodePopup .modal-body .nvs-bet-code-popup-body:before,
#nvsDailyMatchesContainer .nvs-oddsFilter-list-wrapper:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-list-wrapper:before,
#nvsDailyMatchesContainer .nvs-oddsFilter-search-wrapper:after,
#nvsDailyMatchesContainer .nvs-oddsFilter-search-wrapper:before,
#nvsDailyMatchesSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsDailyMatchesSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsInboxListContainer .nvs-inbox-list-wrapper .nvs-inbox-list-item:after,
#nvsInboxListContainer .nvs-inbox-list-wrapper .nvs-inbox-list-item:before,
#nvsLive .nvs-live-content .nvs-live-header:after,
#nvsLive .nvs-live-content .nvs-live-header:before,
#nvsMessageContainer .nvs-message-wrapper:after,
#nvsMessageContainer .nvs-message-wrapper:before,
#nvsMessageContainer:after,
#nvsMessageContainer:before,
#nvsOddsFilterContainer .nvs-oddsFilter-list-wrapper:after,
#nvsOddsFilterContainer .nvs-oddsFilter-list-wrapper:before,
#nvsOddsFilterContainer .nvs-oddsFilter-search-wrapper:after,
#nvsOddsFilterContainer .nvs-oddsFilter-search-wrapper:before,
#nvsOddsFilterSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsOddsFilterSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsOddsPage .odds-container .content-item .market-groups:after,
#nvsOddsPage .odds-container .content-item .market-groups:before,
#nvsOddsPage
  .odds-container
  .content-item
  .tournament
  .tournament-buttons:after,
#nvsOddsPage
  .odds-container
  .content-item
  .tournament
  .tournament-buttons:before,
#nvsOddsPage
  .odds-container.sort-by-tournament
  .market-groups-container
  .market-groups:after,
#nvsOddsPage
  .odds-container.sort-by-tournament
  .market-groups-container
  .market-groups:before,
#nvsOddsPage .odds-container.sort-by-tournament .tournament-buttons:after,
#nvsOddsPage .odds-container.sort-by-tournament .tournament-buttons:before,
#nvsPrintResultSearchContainer .nvs-OddsFilter-search-wrapper:after,
#nvsPrintResultSearchContainer .nvs-OddsFilter-search-wrapper:before,
#nvsSportContainer:after,
#nvsSportContainer:before,
#nvsSportContentContainer:after,
#nvsSportContentContainer:before,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-body:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-body:before,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-header:after,
#nvsSportDetailsListContainer
  .nvs-SportDetailsTable
  .nvs-SportDetailsTable-header:before,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-body:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-body:before,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-header:after,
#nvsSportDetailsListTotalContainer
  .nvs-SportDetailsTotalTable
  .nvs-SportDetailsTotalTable-header:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body-row:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body-row:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-body:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-header:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable
  .nvs-transactionHistoryDetail-betSlip-ticketTable-header:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket
  .nvs-transactionHistoryDetail-betSlip-ticketTable:before,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket:after,
#nvsTransactionHistoryDetailContainer
  .nvs-transactionHistoryDetail--table-wrapper
  .nvs-transactionHistoryDetail--betSlip-table
  .nvs-transactionHistoryDetail--betSlip-ticket:before,
#registerFormContainer .form-wrapper .segment:after,
#registerFormContainer .form-wrapper .segment:before,
#registerFormContainer .form-wrapper:after,
#registerFormContainer .form-wrapper:before,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticChart:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticChart:before,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticTable:after,
#userStatisticListContainer
  .nvsUserStatisticListWrapper
  .nvs-UserStatisticTable:before,
#withdrawContainer #methodContainer:after,
#withdrawContainer #methodContainer:before,
#withdrawContainer:after,
#withdrawContainer:before,
.clearfix:after,
.clearfix:before,
.header-menu-loggedOut:after,
.header-menu-loggedOut:before,
.header-menu-register:after,
.header-menu-register:before,
.navbar-content-menu
  .navbar-content-menu-wrapper
  .navbar-content-menu-container:after,
.navbar-content-menu
  .navbar-content-menu-wrapper
  .navbar-content-menu-container:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row:after,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row:before,
.nvs-AccountListTable .nvs-AccountListTable-body:after,
.nvs-AccountListTable .nvs-AccountListTable-body:before,
.nvs-AccountListTable .nvs-AccountListTable-header:after,
.nvs-AccountListTable .nvs-AccountListTable-header:before,
.nvs-BalanceReportTable .nvs-BalanceReportTable-header:after,
.nvs-BalanceReportTable .nvs-BalanceReportTable-header:before,
.nvs-betHistoryDetail--button-wrapper:after,
.nvs-betHistoryDetail--button-wrapper:before,
.nvs-check-coupon-container .nvsCheckCoupon-entry:after,
.nvs-check-coupon-container .nvsCheckCoupon-entry:before,
.nvs-header-navbar-date-time:after,
.nvs-header-navbar-date-time:before,
.nvs-home:after,
.nvs-home:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-event:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-event:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-odds-wrapper:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper
  .nvsLatestResultItem-odds-wrapper:before,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper:after,
.nvs-latest-results-container
  .nvsLatestResultList
  .nvsLatestResultItemWrapper:before,
.nvs-latest-winners-container .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container .nvsLatestWinnerItem-gain:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-gain:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-gain:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-info:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper
  .nvsLatestWinnerItem-info:before,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper:after,
.nvs-latest-winners-container
  .nvsLatestWinnerList
  .nvsLatestWinnerItemWrapper:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-event:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-event:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-odds-wrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper
  .item-odds-wrapper:before,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper:after,
.nvs-market-movers-list-container
  .list-wrapper
  .market-movers-list
  .item-wrapper:before,
.nvs-mixed-view-container
  .nvs-mixed-view-item
  .nvs-mixed-view-sport-container
  .nvs-mixed-view-sport-event-container
  .nvs-mixed-view-sport-event-item-container
  .nvs-mixed-view-sport-event-item-content
  .nvs-mixed-view-sport-event-content.sport-type-3
  .nvs-mixed-view-event-score-container
  .nvs-mixed-view-event-score:after,
.nvs-mixed-view-container
  .nvs-mixed-view-item
  .nvs-mixed-view-sport-container
  .nvs-mixed-view-sport-event-container
  .nvs-mixed-view-sport-event-item-container
  .nvs-mixed-view-sport-event-item-content
  .nvs-mixed-view-sport-event-content.sport-type-3
  .nvs-mixed-view-event-score-container
  .nvs-mixed-view-event-score:before,
.nvs-products-menu-container:after,
.nvs-products-menu-container:before,
.nvs-RevenueReportTable
  .nvs-RevenueReportTable-body
  .nvs-RevenueReportTable-body-row:after,
.nvs-RevenueReportTable
  .nvs-RevenueReportTable-body
  .nvs-RevenueReportTable-body-row:before,
.nvs-RevenueReportTable .nvs-RevenueReportTable-body:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-body:before,
.nvs-RevenueReportTable .nvs-RevenueReportTable-header:after,
.nvs-RevenueReportTable .nvs-RevenueReportTable-header:before,
.nvs-RevenueReportTable:after,
.nvs-RevenueReportTable:before,
.nvs-simple-view-container
  .nvs-simple-view-item
  .nvs-simple-view-sport-container
  .nvs-simple-view-sport-event-container
  .nvs-simple-view-sport-event-item-container
  .nvs-simple-view-sport-event-item-content
  .nvs-simple-view-sport-event-content.sport-type-3
  .nvs-simple-view-event-score-container
  .nvs-simple-view-event-score:after,
.nvs-simple-view-container
  .nvs-simple-view-item
  .nvs-simple-view-sport-container
  .nvs-simple-view-sport-event-container
  .nvs-simple-view-sport-event-item-container
  .nvs-simple-view-sport-event-item-content
  .nvs-simple-view-sport-event-content.sport-type-3
  .nvs-simple-view-event-score-container
  .nvs-simple-view-event-score:before,
.nvs-ticket-content-event-group
  .nvs-goal-scorer-ticket-content-event-group-item
  .nvs-goal-scorer-ticket-content-event-container
  .nvs-goal-scorer-ticket-content-event-info:after,
.nvs-ticket-content-event-group
  .nvs-goal-scorer-ticket-content-event-group-item
  .nvs-goal-scorer-ticket-content-event-container
  .nvs-goal-scorer-ticket-content-event-info:before,
.nvs-ticket-content-event-group
  .nvs-ticket-content-event-group-item
  .nvs-ticket-content-event-info:after,
.nvs-ticket-content-event-group
  .nvs-ticket-content-event-group-item
  .nvs-ticket-content-event-info:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header
  .nvs-odds-page--odds--content--odds--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header
  .nvs-odds-page--odds--content--odds--header--inner:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--header:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds
  .nvs-odds-page--odds--content--event--content--odds--item
  .nvs-odds-page--odds--content--event--odds--row:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds
  .nvs-odds-page--odds--content--event--content--odds--item
  .nvs-odds-page--odds--content--event--odds--row:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item
  .nvs-odds-page--odds--content--event--content--odds:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--inner
  .nvs-odds-page--odds--event--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner
  .nvs-odds-page--odds--content--odds--sub--header--item:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner
  .nvs-odds-page--odds--content--odds--sub--header--item:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header
  .nvs-odds-page--odds--content--odds--sub--header--inner:before,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header:after,
.odds-table.normal.asian
  .nvs-odds-page--odds--content
  .nvs-odds-page--odds--content--sub--header:before,
.odds-table.normal
  .content--inner
  .event
  .event-content--odds
  .event-content--odds--item
  .event-odds--row:after,
.odds-table.normal
  .content--inner
  .event
  .event-content--odds
  .event-content--odds--item
  .event-odds--row:before,
.odds-table.normal .content--inner .event:after,
.odds-table.normal .content--inner .event:before,
.odds-table.normal .content .content--header .odd-header-inner:after,
.odds-table.normal .content .content--header .odd-header-inner:before,
.odds-table.normal .content .content--header:after,
.odds-table.normal .content .content--header:before,
.odds-table.normal .content .sub-header .inner .item:after,
.odds-table.normal .content .sub-header .inner .item:before,
.odds-table.normal .content .sub-header .inner:after,
.odds-table.normal .content .sub-header .inner:before,
.odds-table.normal .content .sub-header:after,
.odds-table.normal .content .sub-header:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-content:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-content:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header
  .nvs-ticket-combinations-table-pot-winnings-header
  .nvs-ticket-combinations-table-min-max-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header
  .nvs-ticket-combinations-table-pot-winnings-header
  .nvs-ticket-combinations-table-min-max-header:before,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header:after,
.ticket-loaded
  .nvs-ticket-combinations-group
  .nvs-ticket-combinations-table-container
  .nvs-ticket-combinations-table-header:before,
.ticket-loaded .nvs-ticket-header-button-group:after,
.ticket-loaded .nvs-ticket-header-button-group:before,
.ticket-loaded
  .nvs-ticket-place-area
  .nvs-ticket-place-area-reset-save-button-group:after,
.ticket-loaded
  .nvs-ticket-place-area
  .nvs-ticket-place-area-reset-save-button-group:before,
.ticket-loaded .nvs-ticket-place-area .nvs-ticket-place-stake-group:after,
.ticket-loaded .nvs-ticket-place-area .nvs-ticket-place-stake-group:before,
.transfer-container .transfer-inputs-container .transfer-input-sections:after,
.transfer-container .transfer-inputs-container .transfer-input-sections:before {
  content: " ";
  display: table;
}
.nvs-row-level-0 .nvs-AccountListTable-header-cell {
  background-color: #181818;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell {
  display: block;
  float: left;
  border: 1px solid #1f1f1f;
  width: 11%;
  height: 35px;
  line-height: 35px;
  text-align: right;
  padding-right: 3px;
  position: relative;
  max-width: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell:nth-child(-n + 5) {
  text-align: center;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell.sub-level,
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell.sub-level0 {
  width: 10%;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header--cell-open {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  display: block;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell.symbol-labels {
  width: 10%;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header--cell-open:before {
  content: "\f067";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}

.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper {
  width: 105%;
  padding: 5px;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-withdraw,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-withdraw {
  font-size: 0.875em;
  height: 25px;
  line-height: 25px;
  width: 12%;
  color: #d5d5d5;
  background-color: #181818;
  border: 1px solid #181818;
  margin-right: -2px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  float: left;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-withdraw {
  background-color: #333131;
  border: 1px solid #333131;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-withdraw-icon,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-withdraw
  .nvs-AffiliateTransferTable-body--transfer-withdraw-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-withdraw-icon:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-withdraw
  .nvs-AffiliateTransferTable-body--transfer-withdraw-icon:before {
  content: "\f068";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-value,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-value {
  font-size: 0.8125em;
  height: 25px;
  width: 60%;
  display: block;
  float: left;
  color: #d5d5d5;
  text-align: center;
  background-color: #181818;
  border: 1px solid #181818;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-value {
  background-color: #333131;
  border: 1px solid #333131;
}
.nvs-AccountListTable-header-cell
  input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-deposit,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-deposit {
  font-size: 0.875em;
  height: 25px;
  line-height: 25px;
  width: 12%;
  color: #d5d5d5;
  background-color: #181818;
  border: 1px solid #181818;
  margin-left: -2px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  float: left;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-deposit {
  background-color: #333131;
  border: 1px solid #333131;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-deposit-icon,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-deposit
  .nvs-AffiliateTransferTable-body--transfer-deposit-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--transfer-deposit-icon:before,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper
  .nvs-AffiliateTransferTable-body--transfer-deposit
  .nvs-AffiliateTransferTable-body--transfer-deposit-icon:before {
  content: "\f067";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}

.disabled {
  color: #e67878;
}

.enabled {
  color: #5b9bd5;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  background: rgba(0, 0, 0, 0.75);
}
.fade.in {
  opacity: 1;
}
.modal.in .modal-dialog {
  transform: translate(0);
}
.modal-content {
  border: none;
}
.modal-content {
  background-color: #181818;
}
@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  }
}
#amount-transfer-confirm {
  background-color: #181818;
  color: #d5d5d5;
  padding: 20px;
}
#amount-transfer-confirm .amount-transfer-confirm-header {
  display: flex;
  flex-direction: column;
}
#amount-transfer-confirm .amount-transfer-confirm-header p,
#amount-transfer-confirm .amount-transfer-confirm-header span {
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
#amount-transfer-confirm .confirm-container,
#amount-transfer-confirm h2 {
  text-align: center;
}
#amount-transfer-confirm .confirm-container .confirm-yes {
  background-color: #22a152;
}
#amount-transfer-confirm .confirm-container .confirm-no,
#amount-transfer-confirm .confirm-container .confirm-yes {
  width: 100px;
  color: #d5d5d5;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#amount-transfer-confirm .confirm-container .confirm-no {
  background-color: #d06261;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header--cell-close:before {
  content: "\f068";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
.nvs-AccountListTable .nvs-AccountListTable-body {
  width: 100%;
  display: block;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-row {
  display: block;
  position: relative;
}
.nvs-sub-row-level-1 .nvs-AccountListTable-body-cell {
  background-color: #313131;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell {
  display: block;
  float: left;
  border: 1px solid #1f1f1f;
  width: 11%;
  height: 35px;
  line-height: 35px;
  text-align: right;
  padding-right: 3px;
  position: relative;
  max-width: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell:nth-child(-n + 5) {
  text-align: center;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell.sub-level1 {
  width: 9%;
  margin-left: 1%;
}
.nvs-sub-row-level-1
  > .nvs-AccountListTable-body-row:hover
  > .nvs-AccountListTable-body-cell {
  background-color: rgba(49, 49, 49, 0.5);
}

.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body--cell-open {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  display: block;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body--cell-open:before {
  content: "\f067";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body--cell-close:before {
  content: "\f068";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell.symbol-labels {
  width: 10%;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AffiliateTransferTable-body--btn-wrapper {
  width: 100%;
  padding: 0.2rem;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell.actions,
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-body-cell.actions,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell.actions {
  width: 10%;
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header-cell--action-btn,
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body-cell--action-btn {
  float: left;
  width: 23%;
  height: 25px;
  line-height: 25px;
  margin: 5px 2px;
  color: #d5d5d5;
  font-size: 0.875em;
  border: 0;
  border-radius: 4px;
  max-width: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body-cell--action-btn.editPassword-btn {
  background-color: #313131;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header-cell--action-btn.editPassword-btn {
  background-color: #313131;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .nvs-AccountListTable-body-cell--action-btn.cancel-user-btn {
  background-color: #d06261;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-header
  .nvs-AccountListTable-header-cell
  .nvs-AccountListTable-header-cell--action-btn.cancel-user-btn {
  background-color: #d06261;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell.sub-level2 {
  width: 8%;
  margin-left: 2%;
}
.nvs-sub-row-level-2
  > .nvs-AccountListTable-body-row:hover
  > .nvs-AccountListTable-body-cell {
  background-color: rgba(79, 79, 79, 0.5);
}
.nvs-sub-row-level-2 .nvs-AccountListTable-body-cell {
  background-color: #4f4f4f;
  color: #d5d5d5;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell.sub-level3 {
  width: 7%;
  margin-left: 3%;
}
.nvs-sub-row-level-3 .nvs-AccountListTable-body-cell {
  background-color: rgba(79, 79, 79, 0.7);
  color: #d5d5d5;
}
.icon-user3:before {
  content: "\f007";
  font-size: 1.5em;
}
.icon-shop:before {
  content: "\f21b";
  font-size: 1.5em;
}
.icon-admin:before {
  content: "\f0c0";
  font-size: 1.5em;
  color: #22a152;
}
.icon-superadmin:before {
  content: "\f0c0";
  font-size: 1.5em;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
#nvs-AccountList-disableUserContainer {
  background: #272729;
}
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-title {
  font-weight: 800;
  color: #d5d5d5;
  margin-bottom: 20px;
}
#nvs-AccountList-disableUserContainer .nvs-AccountList-disableUser-body {
  width: 100%;
  margin-bottom: 10px;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-label {
  color: #d5d5d5;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.8125em;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons {
  display: inline-block;
  float: left;
  width: 50%;
  padding: 15px;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons
  .nvs-AccountList-disableUser-body-save-btn {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-weight: 600;
  padding: 0 12px;
  font-size: 0.875em;
  border: 0;
  color: #d5d5d5;
  border-radius: 3px;
  background-color: #313131;
  position: relative;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons
  .nvs-AccountList-disableUser-body-save-btn
  .save-btn-icon {
  color: #d5d5d5;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons
  .nvs-AccountList-disableUser-body-close-btn {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-weight: 600;
  padding: 0 12px;
  font-size: 0.875em;
  border: 0;
  color: #d5d5d5;
  border-radius: 3px;
  background-color: #d06261;
  position: relative;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons
  .nvs-AccountList-disableUser-body-save-btn
  .save-btn-icon:before {
  content: "\f0c7";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
#nvs-AccountList-disableUserContainer
  .nvs-AccountList-disableUser-body
  .nvs-AccountList-disableUser-body-buttons
  .nvs-AccountList-disableUser-body-close-btn
  .close-btn-icon:before {
  content: "\f00d";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
.nvs-AccountListTable
  .nvs-AccountListTable-body
  .nvs-AccountListTable-body-cell
  .disabled {
  color: #e67878;
}
#nvs-AccountList-editPasswordContainer {
  background: #272729;
}
#nvs-AccountList-editPasswordContainer .nvs-AccountList-editPassword-title {
  font-weight: 800;
  color: #d5d5d5;
  margin-bottom: 20px;
}
#nvs-AccountList-editPasswordContainer .nvs-AccountList-editPassword-body {
  width: 100%;
  margin-bottom: 10px;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-label {
  text-transform: uppercase;
  color: #d5d5d5;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.8125em;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-input {
  border: 1px solid #181818;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 5px;
  border-radius: 3px;
  background-color: #1f1f1f;
  color: #d5d5d5;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-checkbox {
  display: inline-block;
  float: left;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-checkbox
  .nvs-AccountList-editPassword-body-checkbox-input-label {
  position: relative;
  display: block;
  width: 100%;
  margin: 5px 0;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-checkbox
  .nvs-AccountList-editPassword-body-checkbox-input-label
  .nvs-AccountList-editPassword-body-checkbox-input {
  position: absolute;
  left: -9999px;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons {
  display: inline-block;
  float: right;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-save-btn {
  float: left;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  padding: 0 12px;
  font-size: 14px;
  border: 0;
  color: #d5d5d5;
  margin: 3px;
  border-radius: 3px;
  background-color: #313131;
  position: relative;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-save-btn
  .save-btn-icon {
  color: #d5d5d5;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-save-btn
  .save-btn-icon:before {
  content: "\f0c7";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-close-btn {
  float: left;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  padding: 0 12px;
  font-size: 14px;
  border: 0;
  color: #d5d5d5;
  margin: 3px;
  border-radius: 3px;
  background-color: #d06261;
  position: relative;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-close-btn
  .close-btn-icon {
  color: #d5d5d5;
  display: inline-block;
  text-align: center;
  line-height: 35px;
}
#nvs-AccountList-editPasswordContainer
  .nvs-AccountList-editPassword-body
  .nvs-AccountList-editPassword-body-buttons
  .nvs-AccountList-editPassword-body-close-btn
  .close-btn-icon:before {
  content: "\f00d";
  font-family: FontAwesome, sans-serif;
  line-height: inherit;
}
