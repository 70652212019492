.loading-logo {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.loading-svg {
  margin: auto;
  width: 80%;
  padding: 30vh 30vw;
}
@media (min-width: 992px) {
  .loading-svglogo g {
    transform: translate(0px, 0px);
  }
}
@media (max-width: 992px) {
  .loading-svg {
    margin: auto;
    width: 80%;
    padding: 30vh 15vw;
  }
}

.loading-svg svg {
  width: 40vw;
  height: 40vh;
}
@media (max-width: 992px) {
  .loading-svg svg {
    width: 100vw;
    height: 40vh;
  }
}
@keyframes dash_fill_3 {
  0% {
    fill: #fff;
  }
  20% {
    fill: #fff;
  }
  40% {
    fill: url(#grad1);
  }
  60% {
    fill: #fff;
  }
  80% {
    fill: #fff;
  }
  100% {
    fill: url(#grad1);
  }
}
@keyframes dash_fill_2 {
  0% {
    fill: url(#grad1);
  }
  20% {
    fill: #fff;
  }
  40% {
    fill: #fff;
  }
  60% {
    fill: url(#grad1);
  }
  80% {
    fill: #fff;
  }
  100% {
    fill: #fff;
  }
}
@keyframes dash_fill_1 {
  0% {
    fill: #fff;
  }
  20% {
    fill: url(#grad1);
  }
  40% {
    fill: #fff;
  }
  60% {
    fill: #fff;
  }
  80% {
    fill: url(#grad1);
  }
  100% {
    fill: #fff;
  }
}
body.preloading .preloader {
  opacity: 1;
  pointer-events: all;
}
#preloader_border_3-line {
  animation: dash_fill_3 2.5s linear forwards infinite;
}
#preloader_border_2-line {
  animation: dash_fill_2 2.5s linear forwards infinite;
}
#preloader_border_1-line {
  animation: dash_fill_1 2.5s linear forwards infinite;
}
