.header-slider__inner {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.swiper-container-multirow-column > .swiper-wrapper,
.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}
.header-slider {
  position: relative;
  overflow: hidden;
  margin: 1px 0 1px;
}

@media screen and (max-width: 1023px) {
  .header-slider {
    margin-top: 0;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__container.container {
    padding: 0;
  }
}

.header-slider__inner {
  position: relative;
  border-radius: 0px;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .header-slider__inner {
    border-radius: 0;
  }
}

.header-slider__age-apply {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 2;
  color: #fff;
  font-family: GTWalsheimProRegular, sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 1023px) {
  .header-slider__age-apply {
    bottom: 10px;
    right: 10px;
    font-size: 10px;
  }
}

.header-slider__image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  overflow: hidden;
}

.header-slider__image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 39.275%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.83))
  );
  background: linear-gradient(270deg, transparent, rgba(0, 0, 0, 0.83));
}

.header-slider__image-wrapper picture {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .header-slider__image-wrapper picture {
    position: relative;
    display: block;
    width: auto;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) and (max-width: 640px) {
}

@media only screen and (max-width: 767px) and (min-width: 640px) {
  .header-slider__image-wrapper picture {
    right: 0;
  }
}

@media only screen and (max-width: 767px) and (max-width: 320px) {
  .header-slider__image-wrapper picture {
    right: -100px;
  }
}

.header-slider__image {
  display: block;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .header-slider__image {
    height: 100%;
    width: auto;
    margin-left: auto;
  }
}

.header-slider__content-inner {
  position: relative;
}

.header-slider__content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 0 48px 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 1323px) {
  .header-slider__content-wrapper {
    max-width: 320px;
    padding: 0 0 48px 24px;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__content-wrapper {
    max-width: 170px;
    padding-bottom: 0;
  }

  .header-slider__content-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(10%, rgba(0, 0, 0, 0.7)),
      to(transparent)
    );
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 10%, transparent);
  }
}

@media screen and (max-width: 767px) {
  .header-slider__content-wrapper {
    padding-left: 10px;
    max-width: 150px;
  }
}

.header-slider__content-wrapper b,
.header-slider__content-wrapper strong {
  font-family: GTWalsheimProMedium, sans-serif;
}

.header-slider__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.header-slider__content h3 {
  text-align: left;
  font: 32px/1.25 GTWalsheimProRegular, sans-serif;
  color: #fff;
}

@media screen and (max-width: 1323px) {
  .header-slider__content h3 {
    font-size: 26px;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__content h3 {
    font-size: 16px;
  }
}

.header-slider__content .btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  height: 40px;
  margin-top: 25px;
  line-height: 1;
  padding-top: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 9px;
  background-color: #b12509;
  font-family: GTWalsheimProMedium, sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 1323px) {
  .header-slider__content .btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__content .btn {
    display: none;
  }
}

.header-slider__provider {
  display: inline-block;
  position: relative;
  font: 14px/1 GTWalsheimProMedium, sans-serif;
  color: #cbd2dd;
  margin-bottom: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1323px) {
  .header-slider__provider {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__provider {
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .header-slider__provider {
    margin-bottom: 10px;
  }
}

.header-slider__navigation {
  position: absolute;
  bottom: 16px;
  left: 0;
  z-index: 1;
  padding-left: 38px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1323px) {
  .header-slider__navigation {
    padding-left: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .header-slider__navigation {
    display: none;
  }
}

.header-slider__navigation-next,
.header-slider__navigation-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.header-slider__navigation-next:hover,
.header-slider__navigation-prev:hover {
  background-color: hsla(0, 0%, 100%, 0.33);
}

.header-slider__navigation-next svg,
.header-slider__navigation-prev svg {
  width: 20px;
  height: 20px;
}

.header-slider__navigation-next {
  margin-left: 15px;
}

.header-slider__inner-slide {
  -webkit-transition: 0.25s;
  transition: 0.25s;
  opacity: 0.5;
  overflow: hidden;
  position: relative;
}

.header-slider__inner-slide:before {
  content: "";
  display: block;
  padding-bottom: 24.28%;
}

.header-slider__inner-slide > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .header-slider__inner-slide {
    overflow: hidden;
    border-radius: 0px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 640px) and (max-width: 767px) {
  .header-slider__inner-slide {
    height: calc(20.47244vw + 28.97638px);
  }
}

@media only screen and (max-width: 767px) and (min-width: 767px) {
  .header-slider__inner-slide {
    height: 186px;
  }
}

@media only screen and (max-width: 767px) and (max-width: 640px) {
  .header-slider__inner-slide {
    height: 109px;
  }
}

.swiper-slide-active .header-slider__inner-slide {
  opacity: 1;
}
@media (max-width: 600px) {
  .header-slider__content-wrapper {
    display: none;
  }
}
