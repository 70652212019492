.contain-casino {
  margin: 0px;
}

@media only screen and (min-width: 1324px) {
  .contain-casino {
    min-height: 400px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
#nvsCasinoContainer {
  width: 100%;
  display: flex;
  font-weight: 700;
}
.nvs-casino-wrapper {
  width: 100%;
}

.nvs-casino-content-area {
  width: 100%;
  display: flex;
  position: relative;
}
.nvs-casino-game-container {
  width: 100%;
  padding: 0 10px;
}
@media only screen and (max-width: 760px) {
  .nvs-casino-game-container {
    padding: 0 1px;
  }
}
#nvs-casino-game-wrap {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.2s ease;
}
.nvs-casino-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 100%;
}
.nvs-casino-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10 px;
  background: #fcd301;
  outline: 1 px solid #708090;
}
.nvs-casino-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10 px;
}
.collapse.in {
  display: block;
}
