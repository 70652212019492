.btn-group .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}
.btn-group.dropdown-toggle:focus {
  outline: 0;
}

.btn-group.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
