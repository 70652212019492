.nvs-casino-dropdown {
  width: 270px;
  padding: 0;
  background-color: #181818;
  border-radius: 0;
  left: 14px;
  margin-top: 0;
  overflow: auto;
  overflow-x: hidden;
  max-height: 605px;
}
.nvs-casino-dropdown .nvs-casino-providers-li {
  cursor: pointer;
  font-size: 13px;
}
.nvs-casino-dropdown .nvs-casino-providers-li input {
  display: none;
}
.nvs-casino-dropdown .nvs-casino-providers-li:first-child label {
  border-top-color: transparent !important;
}
.nvs-casino-dropdown .nvs-casino-providers-li .active-provider {
  color: #d5d5d5;
}
.nvs-casino-dropdown .nvs-casino-providers-li:hover {
  background-color: #e3a222;
  color: #1f1f1f;
}
.nvs-casino-dropdown .nvs-casino-providers-li label {
  display: block;
  cursor: pointer;
  font-weight: 400;
  border-top: 1px solid #111;
  padding: 12px 0;
  margin: 0 15px;
}
.nvs-casino-dropdown .nvs-casino-providers-li .active-provider:before {
  content: "\f00c";
  font-family: FontAwesome, sans-serif;
  font-size: 10px;
  color: #d5d5d5;
  line-height: 10px;
  right: 10px;
  position: absolute;
}
ul li {
  list-style: none;
}
.nvs-casino-dropdown .nvs-search-provider {
  position: relative;
}
.nvs-casino-dropdown .nvs-search-provider .nvs-search-provider-input {
  height: 35px;
  width: 100%;
  padding: 0 30px 0 15px;
  color: #d5d5d5;
  background: #181818;
}
.nvs-casino-dropdown .nvs-search-provider:after {
  content: "\f002";
  font-family: FontAwesome, sans-serif;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 13px;
}
.nvs-casino-dropdown .nvs-casino-providers-li label .providerItem {
  display: flex;
  justify-content: space-between;
}
#nvs-casino-search:focus-visible {
  border: none;
}

.nvs-casino-dropdown
  .nvs-casino-providers-li
  label
  .providerItem
  span:last-child {
  margin-right: 20px;
}
