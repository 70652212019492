@media (max-width: 767px) {
  .footercontain {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #292b2c;
    display: flex;
  }
}

@media (min-width: 767px) {
  .footercontain {
    display: none;
  }
}
.footer-item {
  width: 20%;
  color: white;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  background: 0 0;
  color: #a8b0bf;
  font-size: 12px;
}
