.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}
#profileContainer {
  display: flex;
  width: 100%;
  padding: 0 10px;
}
.container-fluid:after,
.container-fluid:before {
  content: " ";
  display: table;
}
.container-fluid .row:after,
.container-fluid .row:before {
  content: " ";
  display: table;
}
#profileContainer .profile-content {
  background-color: #181818;
  padding: 10px 15px;
  margin: 10px 0 10px 10px;
  animation: d 1.5s;
  border-radius: 3px;
  width: calc(100% - 300px);
}
@media (max-width: 767px) {
  #profileContainer .profile-content {
    width: calc(100%);
    padding: 1px 1px;
    height: 90vh;
  }
  .container-fluid {
    padding-right: 15px;
    padding-left: 0cm;
  }
}
@media only screen and (min-width: 1441px) {
  #profileContainer .profile-content {
    width: calc(100% - 270px);
  }
}
@media only screen and (min-width: 1281px) {
  #profileContainer .profile-content {
    width: calc(100% - 260px);
  }
}
@media only screen and (min-width: 1025px) {
  #profileContainer .profile-content {
    width: calc(100% - 250px);
  }
}
#profileContainer .profile-content h4 {
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 24px;
  color: rgb(213, 213, 213);
}
