.nvs-casino-filter-wrapper {
  width: 100%;
  padding: 7px;
  background: #272729;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .nvs-casino-filter-wrapper {
    display: none;
  }
}
.nvs-casino-title-area {
  width: 26%;
  margin-right: 10px;
}
.nvs-casino-search-label {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 13px;
  font-weight: 400;
  line-height: 50px;
}
.nvs-casino-search-label::after {
  content: "\f002";
  font-family: FontAwesome, sans-serif;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  color: #e3a222;
  width: 40px;
  text-align: center;
  font-size: 13px;
}
.nvs-casino-search-input {
  height: 50px;
  width: 100%;
  border: 1px solid #1f1f1f;
  color: #d5d5d5;
  padding: 0 40px 0 15px;
  background: #181818;
  border-radius: 0.25rem;
}
.nvs-casino-chose-play-area {
  width: 19%;
  margin-right: 10px;
  background: linear-gradient(180deg, #4f4f4f, #313131);
  padding: 10px 0;
  text-align: center;
  color: #d5d5d5;
  height: 50px;
  border-radius: 0.25rem;
}
.play-area-type-cont {
  display: inline-block;
  padding: 3px;
}
.on-side {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
  padding: 4px 10px;
  opacity: 0.3;
  font-weight: 700;
}
.on-side.active {
  opacity: 1;
}
.nvs-casino-wrapper
  .nvs-casino-filter-wrapper
  .nvs-casino-chose-play-area
  .switch__container
  .switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999 px;
}

.nvs-casino-chose-play-area .play-area-type-cont .on-side.active,
#nvsCasinoContainer
  .nvs-casino-wrapper
  .nvs-casino-filter-wrapper
  .nvs-casino-chose-play-area
  .play-area-type-cont
  .pop-up.active {
  opacity: 1;
}
.switch__container {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}
.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}

.nvs-casino-chose-play-area .switch__container .switch.switch--shadow + label {
  width: 40px;
  height: 20px;
  border-radius: 60px;
}
.nvs-casino-filter-wrapper
  .nvs-casino-chose-play-area
  .switch__container
  .switch
  + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}
.switch-shadow-label::after,
.switch-shadow-label::before {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

.switch__container .switch.switch--shadow:checked + label:before {
  background-color: #272729;
}

.switch__container .switch.switch--shadow:checked + label:after {
  transform: translateX(20px);
}

.switch__container .switch.switch--shadow + label:before {
  right: 1px;
  background-color: #272729;
  border-radius: 60px;
  transition: background 0.4s;
}
.switch-shadow-label::after {
  width: 18px;
  background-color: #e3a222;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}
.pop-up {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
  padding: 4px 10px;
  opacity: 0.3;
  font-weight: 700;
}
.nvs-casino-providers {
  width: 19%;
  margin-right: 10px;
}
.nvs-casino-providers-content {
  width: 100%;
  height: 50px;
  background-color: #181818;
  color: #d5d5d5;
  border-radius: 3px;
  font-size: 13px;
  text-align: left;
}

.nvs-casino-providers-button {
  background: linear-gradient(180deg, #4f4f4f, #313131);
  height: 50px;
  border: none;
  text-align: left;
  padding-left: 15px;
  display: block;
  width: 100%;
  position: relative;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1601px) {
  .nvs-casino-providers-button {
    font-size: 13px;
  }
}
button {
  transition: all 0.3s ease;
}
.nvs-casino-providers-button::after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  font-size: 13px;
  right: 15px;
  top: 0;
  bottom: 0;
  height: 55px;
  line-height: 55px;
}
.nvs-casino-favourites {
  width: 20%;
}
.nvs-casino-favourites-label {
  width: 100%;
  background-color: #181818;
  color: #d5d5d5;
  border-radius: 3px;
  font-size: 20px;
  text-align: left;
}
.nvs-casino-favourites-button {
  width: 100%;
  background: linear-gradient(180deg, #4f4f4f, #313131);
  border: none;
  text-align: left;
  padding: 15px;
  font-size: 13px;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1601px) {
  .nvs-casino-favourites-button {
    font-size: 13px;
  }
}
.nvs-casino-favourites-button-span {
  padding-left: 3px;
  display: inline-block;
  margin-top: 4px;
}
.nvs-casino-favourites-button i {
  color: #d5d5d5;
  font-size: 14px;
  line-height: 20px;
  float: right;
}
.nvs-casino-providers-button:hover {
  background: linear-gradient(180deg, #575757, #393939);
  color: #d5d5d5;
}
.nvs-casino-providers-button {
  color: #d5d5d5;
}
.nvs-casino-favourites-button:hover {
  background: linear-gradient(180deg, #575757, #393939);
  color: #d5d5d5;
}
.nvs-casino-favourites-button {
  color: #d5d5d5;
}
