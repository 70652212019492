#betHistory .bet-history-top-section {
  display: flex;
  color: rgb(213, 213, 213);
}
#betHistory .bet-history-filter {
  width: 50%;
  margin-right: 10px;
}
.input-group-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom: 10px;
}
@media only screen and (max-width: 560px) {
  .input-group-container {
    display: block;
  }
}

.input-group-item:first-child {
  margin-left: 0;
}
.input-group-item label {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}
.input-group-item {
  width: 100%;
  margin: 0 5px;
}
@media only screen and (max-width: 560px) {
  .input-group-item {
    margin: 0 0;
  }
}
.button-primary {
  color: #d5d5d5;
  background-color: #23a152;
}
.input-group-item button {
  white-space: nowrap;
  height: 35px;
}

#BetHistoryFilterContainer .input-group-container .input-group-item .button {
  width: 100px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.input-group-item.inline {
  align-self: flex-end;
}
.input-group-item:last-child {
  margin-right: 0;
}
#betHistory .bet-history-stats {
  width: 50%;
  position: relative;
  top: -10px;
}
#BetHistoryFilterContainer .button {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.3s ease;
}
.button i {
  margin-right: 5px;
}
input.form-input,
select.form-input,
textarea.form-input {
  color: #d5d5d5;
  border-radius: 3px;
  background-color: #313131;
  height: 35px;
  width: 100%;
  font-size: 12px;
  padding: 0 10px;
  font-weight: 400;
  border: 0;
}
