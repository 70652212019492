#profileContainer .profile-sidebar {
  width: 300px;
  animation: d 1.5s;
}
@media (max-width: 767px) {
  .profile-sidebar {
    display: none;
  }
}
@media only screen and (min-width: 1441px) {
  #profileContainer .profile-sidebar {
    width: 270px;
  }
}
@media only screen and (min-width: 1281px) {
  #profileContainer .profile-sidebar {
    width: 260px;
  }
}

@media only screen and (min-width: 1025px) {
  #profileContainer .profile-sidebar {
    width: 250px;
  }
}
.nvs-sidebar-box {
  padding-top: 10px;
}
#nvsProfileSidebarContainer {
  width: 100%;
}
#nvsProfileSidebarContainer .nvs-profile-sidebar--content {
  background-color: #181818;
  margin: 5px 0 10px;
  padding: 12px 15px;
  position: relative;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--header {
  font-size: 0.8125em;
  color: #d5d5d5;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--menu {
  margin: 0;
  padding: 0;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--menu
  .nvs-profile-sidebar--content--menu--list {
  list-style-type: none;
  position: relative;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--menu
  .nvs-profile-sidebar--content--menu--list
  .nvs-profile-sidebar--content--menu--list-element {
  background-color: #1f1f1f;
  color: #d5d5d5;
  display: block;
  margin-bottom: 1px;
  padding: 10px;
  font-size: 0.75em;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}
#nvsProfileSidebarContainer .nvs-profile-sidebar--content:first-child {
  margin-top: 0;
}
#nvsProfileSidebarContainer h4,
#nvsProfileSidebarContainer h5 {
  font-weight: 700;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--menu
  .nvs-profile-sidebar--content--menu--list
  .nvs-profile-sidebar--content--menu--list-element.active {
  background: linear-gradient(
    #f7d112 0,
    #f2c319 21.02%,
    #ebb21f 38.93%,
    #e3a222 55.67%,
    #dc9125 73.96%
  );
  color: #1f1f1f;
  transition: all 0.3s ease;
}
#nvsProfileSidebarContainer
  .nvs-profile-sidebar--content
  .nvs-profile-sidebar--content--menu
  .nvs-profile-sidebar--content--menu--list
  .nvs-profile-sidebar--content--menu--list-element:hover {
  background: linear-gradient(
    #f7d112 0,
    #f2c319 21.02%,
    #ebb21f 38.93%,
    #e3a222 55.67%,
    #dc9125 73.96%
  );
  color: #1f1f1f;
  transition: all 0.3s ease;
  cursor: pointer;
}
