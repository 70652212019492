@media only screen and (min-width: 600px) {
  .liked-games {
    display: none;
  }
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.panel-default {
  border-color: #ddd;
}
.panel {
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}
.panel-default {
  border-radius: 0 !important;
}
.liked-games .panel {
  margin-bottom: 6px;
}
.liked-games .panel {
  border: none;
  box-shadow: none;
  border-radius: 0;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading {
  background-image: linear-gradient(180deg, #f5f5f5 0, #e8e8e8);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFF5F5F5",endColorstr="#FFE8E8E8",GradientType=0);
}
.liked-games .panel .panel-heading {
  background-color: #3d4343;
  background-image: none;
  color: #fff;
  border-bottom: 1px solid #3d4343;
  border-radius: 0;
  padding-right: 0;
}
.liked-games .panel .custom-panel-heading {
  padding: 0;
  display: flex;
}
.liked-games .panel .custom-panel-heading > .form-group {
  margin: 0;
  flex: 1;
}
#fancy-checkbox-danger {
  display: none;
}
.panel-heading .custom-panel-heading .btn-group,
.panel-heading .custom-panel-heading .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.liked-games .panel .custom-panel-heading > .form-group .btn-group {
  display: flex;
  flex: 1;
}
.btn-danger,
.btn-default,
.btn-info,
.btn-primary,
.btn-success,
.btn-warning {
  text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
  box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}
.panel-heading .custom-panel-heading .btn-group-vertical > .btn,
.panel-heading .custom-panel-heading .btn-group > .btn {
  position: relative;
  float: left;
}
.btn-casino .casino-label {
  border-radius: 0;
}
.panel-heading .custom-panel-heading .btn-group > .btn:first-child {
  margin-left: 0;
}
.liked-games .form-group label {
  padding: 8px 6px;
  background: #d24945;
  border: 1px solid #d24945;
  color: #fff;
  text-shadow: none;
  font-size: 12px;
}
.panel-heading
  .custom-panel-heading
  .btn-group
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.liked-games .form-group label {
  padding: 8px 6px;
  background: #d24945;
  border: 1px solid #d24945;
  color: #fff;
  text-shadow: none;
  font-size: 12px;
}
.panel-heading
  .custom-panel-heading
  .btn-group
  > .btn:last-child:not(:first-child),
.panel-heading
  .custom-panel-heading
  .btn-group
  > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.liked-games
  .panel
  .custom-panel-heading
  > .form-group
  .btn-group
  .casino-btn-default {
  flex: 1;
}
.liked-games .panel .custom-panel-heading .casino-panel-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.panel-default .panel-heading .casino-panel {
  display: block;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}
.panel-default .panel-heading .casino-panel .panel-title {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 12px;
}
.panel-default .panel-heading .casino-panel .panel-title:after {
  font-family: FontAwesome, sans-serif;
  content: "\f0d7";
  margin-left: 8px;
}
