@media (max-width: 992px) {
  .svglogo {
    width: 200px;
    height: 70px;
  }
  .svglogo g {
    transform: translate(0px, 16px) scale(0.38, 0.38);
  }
}
@media (min-width: 992px) {
  .svglogo {
    width: 400px;
    height: 100px;
    margin-top: 7px;
  }
  .svglogo g {
    transform: translate(0px, 5px) scale(0.75, 0.75);
  }
}
