#newUserContainer {
  color: #d5d5d5;
}
.form-sub-header {
  font-size: 14px;
  font-weight: 700;
  border-bottom: 2px solid #272729;
  padding-bottom: 5px;
}
.input-group-item .fa-asterisk {
  color: #e74c3c;
}
input.form-input,
select.form-input,
textarea.form-input {
  color: #d5d5d5 !important;
  border-radius: 3px !important;
  background-color: #313131 !important;
  height: 35px !important;
  width: 100% !important;
  font-size: 12px !important;
  padding: 0 10px !important;
  font-weight: 400 !important;
}
.btn-default {
  color: #333;
  background-color: #fff;
}
.form-control,
output {
  display: block;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
}
.form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-default {
  background-image: none;
  border: none;
  box-shadow: none;
  text-shadow: none;
}
.btn-default {
  background-color: #313131;
}
.ui-select-bootstrap .ui-select-toggle {
  position: relative;
}
.ui-select-wrap .btn-default {
  background-color: #313131;
}
.ui-select-wrap .form-control {
  color: #d5d5d5;
}
.ui-select-bootstrap > .ui-select-match > .btn {
  text-align: left !important;
}
.ui-select-wrap .btn-default .ui-select-placeholder {
  color: #afafaf;
  transition: all 0.3s ease;
  text-transform: capitalize;
}

.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}
.ui-select-bootstrap .ui-select-match-text {
  width: 100%;
  padding-right: 1em;
}
.ui-select-bootstrap .ui-select-match-text span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.ui-select-bootstrap .ui-select-match-text span {
  text-shadow: none;
  color: #d5d5d5;
  text-transform: capitalize;
}
.ui-select-bootstrap .ui-select-toggle > .caret {
  position: absolute;
  height: 10 px;
  top: 50%;
  right: 10px;
  margin-top: -2px;
}
.ui-select-wrap .btn-default i.caret {
  color: #afafaf;
}
.ui-select-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}
.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  margin-top: -1px;
}
.ui-select-bootstrap > .ui-select-choices {
  background-color: #181818;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  color: #777;
  white-space: nowrap;
}
#newUserContainer input[type="search"] {
  background-color: #181818;
  border: none;
}
.open > .dropdown-menu {
  display: block;
}
.ui-select-choices-row:hover {
  background-color: #f5f5f5;
}
.ui-select-bootstrap .ui-select-choices-row > span {
  cursor: pointer;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.ui-select-bootstrap .ui-select-choices-row > span {
  color: #d5d5d5;
}
.ui-select-bootstrap .ui-select-choices-row.active > span {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca;
}
.ui-select-bootstrap .ui-select-choices-row.active > span {
  background-color: #f5f5f5;
  color: #000;
}
.form-error p {
  color: #d06261;
  font-size: 10px;
  margin: 3px 0 0;
  font-weight: 600;
}

input.form-input[disabled],
select.form-input[disabled],
textarea.form-input[disabled] {
  color: #7c7c7c !important;
  background-color: #0b0b0b !important;
}
.input-group-item .input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}
.input-group-item .input-with-icon input {
  padding-left: 50px !important;
}
.input-group-item .input-with-icon i {
  position: absolute;
  left: 23px;
}
