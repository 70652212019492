.play-game-wrapper {
  overflow: hidden !important;
}
.play-game-wrapper .modal-dialog {
  width: 100%;
  margin: 0;
  max-width: 100vw;
}
.play-game-wrapper .modal-dialog .modal-content {
  width: 100%;
  height: 100vh;
  color: #d5d5d5;
  background: rgba(0, 0, 0, 0.95);
  border-radius: 0;
}
.play-game-wrapper
  .modal-dialog
  .modal-content
  .game-modal-content
  .close-game-btn {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
}
.play-game-wrapper
  .modal-dialog
  .modal-content
  .game-modal-content
  .game-iframe {
  width: 90%;
  height: 90vh;
  color: #d5d5d5;
  margin-left: 5%;
  margin-top: 2%;
}
.play-game-wrapper
  .modal-dialog
  .modal-content
  .game-modal-content
  .close-game-btn
  i {
  font-size: 46px;
  color: #d5d5d5;
}
