@media only screen and (min-width: 992px) {
  .nvs-casino-game {
    width: 25%;
    margin-top: 17px;
    padding-left: 12.5px;
  }
}
@media only screen and (min-width: 760px) and (max-width: 992px) {
  .nvs-casino-game {
    width: 33.33%;
    margin-top: 8px;
    padding-left: 4.5px;
  }
}
@media only screen and (max-width: 760px) {
  .nvs-casino-game {
    width: 50%;
    margin-top: 4px;
    padding-left: 1.5px;
  }
}
.nvs-casino-game:nth-child(4n + 1) {
  padding-left: 0;
}
.nvs-casino-game-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  border-bottom: none;
  transition: all 0.3s linear;
}

@media only screen and (min-width: 992px) {
  .nvs-casino-game-image {
    position: relative;
    width: 100%;
    /*padding-top: 50%;*/
    height: 200px;
  }
}
@media only screen and (max-width: 450px) {
  .nvs-casino-game-image {
    position: relative;
    width: 100%;
    height: 120px;
  }
}
.nvs-casino-game-mark {
  width: 40px;
  height: 180px;
  background: #cc0001;
  position: absolute;
  top: -45px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
}
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}
.nvs-casino-game-image img {
  width: 100%;
  height: 100%;
}
.nvs-casino-game-mark span {
  position: absolute;
  top: 60px;
  transform: rotate(-90deg);
  color: #fff;
}
.nvs-casino-play-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease;
}
.play-btn {
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  z-index: -1;
  color: #d5d5d5;
  border: 3px solid transparent;
  border-radius: 100%;
  background-color: #fff;
}
.play-btn:after {
  content: "\f04b";
  font-family: FontAwesome, sans-serif;
  color: #f2c319;
}
.nvs-casino-description {
  position: relative;
  height: 45px;
  border-bottom: 2px solid hsla(0, 0%, 84%, 0.075);
  padding: 0 15px;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nvs-casino-description h4 {
  display: inline-block;
  margin: 0;
  height: 45px;
  line-height: 45px;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #e3a222;
  font-size: 0.875em;
  font-weight: 700;
}
@media only screen and (max-width: 760px) {
  .nvs-casino-description h4 {
    font-size: 0.675em;
    font-weight: 500;
  }
}
.nvs-casino-description-favourite {
  display: inline-block;
}
.casino-fav {
  background: transparent;
  position: relative;
}
.nvs-casino-description-favourite span {
  color: #fcd301;
}
.nvs-casino-description-favourite span {
  cursor: pointer;
  width: 45px;
  height: 45px;
  text-align: right;
  line-height: 45px;
}
.play-btn:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
}

.nvs-casino-game-inner:hover {
  border: 3px solid #fcd301;
  border-bottom: none;
}
.nvs-casino-game-inner:hover .nvs-casino-description {
  background: linear-gradient(
    #f7d112 0,
    #f2c319 21.02%,
    #ebb21f 38.93%,
    #e3a222 55.67%,
    #dc9125 73.96%
  );
  border-bottom: 2px solid transparent;
}

.nvs-casino-game-inner:hover .nvs-casino-description h4,
.nvs-casino-game-inner:hover .nvs-casino-description span {
  color: #1f1f1f;
}

.nvs-casino-game-image:active,
.nvs-casino-game-image:hover {
  transition: all 0.3s linear;
}

.nvs-casino-game-image:active .nvs-casino-play-btn,
.nvs-casino-game-image:hover .nvs-casino-play-btn {
  background: rgba(0, 0, 0, 0.3);
}

.nvs-casino-game-image:active .nvs-casino-play-btn .play-btn,
.nvs-casino-game-image:hover .nvs-casino-play-btn .play-btn {
  width: 70px;
  height: 70px;
  z-index: 1;
}
