@media (max-width: 767px) {
  .Sportmain {
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .slid-sport {
    display: block;
  }
}
@media (min-width: 768px) {
  .Sportmain {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .slid-sport {
    display: none;
  }
}
